import {getList} from "@/api/vgm";
import {nanoid} from 'nanoid';
import VGMSearchBar from "@/views/VGM/components/VGMSearchBar.vue";
import VGMTable from "@/views/VGM/components/VGMTable.vue";
import AddVGMDialog from "@/views/VGM/components/AddVGMDialog.vue";
import DeleteVGMDialog from "@/views/VGM/components/DeleteVGMDialog.vue";
import bus from "@/utils/bus";
import moment from "moment";

export default {
    components: {
        VGMTable,
        AddVGMDialog,
        DeleteVGMDialog,
        VGMSearchBar
    },
    data() {
        return {
            fileList: [],
            //table 数据
            page: 1,
            totalPage: 0,
            pageSize: 50,
            totalCount: 0,
            dataLoading: false,
            exportDialogVisible: false,
            tableHeaders: this.RESETTABLE.vgmTableHeader, //列表标题
            tableData: [],
            multipleSelection: [], //多选中的信息
            search_completionstatus: 0, //搜索的舱单状态
            search: {},//搜索框条件，接收SearchBar的返回参数
            loginUserId: '',
            loginUserCompanyId: '',
        }
    },
    mounted() {
        this.init();
    },
    computed: {},
    methods: {
        init() {
            this.resetTableHeaders();
            this.loginUserId = this.$store.state.user.userId;
            if (this.$store.state.user.cid) {
                this.loginUserCompanyId = this.$store.state.user.cid;
                if (this.search_completionstatus !== 3) {
                    this.search.showCompanyId = this.loginUserCompanyId;
                }
            }
            if (this.search_completionstatus === 2) {
                const date_now = moment().format("YYYY-MM-DD");
                const dateRange = [moment(date_now).subtract(0, "days").format("YYYY-MM-DD"), date_now];
                this.search.beginTime = dateRange[0] + ' 00:00:00'
                this.search.endTime = dateRange[1] + ' 23:59:59'
            }
            this.getList();
        }, //初始化界面
        resetTableHeaders() {
            this.tableHeaders = this.RESETTABLE.vgmTableHeader.filter(x => x.status.includes(this.search_completionstatus));
            this.$forceUpdate()
        },
        searchList(data) {
            this.search = data
            this.getList()
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.getList();
        },
        nextPage(page) {
            this.page = page;
            this.getList();
        },
        getList() {
            let data = {
                ...this.search,
                id: this.$store.state.user.userId,
                completionstatus: this.search_completionstatus,
                page: this.page,
                limit: this.pageSize,
            }
            // this.$nextTick(() => {
            //   this.$refs.ShManifestTable.init(this.tableData, this.tableHeaders, 0)
            // })
            this.dataLoading = true;
            getList(data)
                .then((response) => {
                    if (response.data.data) {
                        this.totalPage = response.data.data.totalPage;
                        this.totalCount = response.data.data.totalCount;
                        this.page = response.data.data.currPage;
                        this.pageSize = response.data.data.pageSize;
                        this.tableData = response.data.data.list.map((item) => {
                            item.vesselvoyage = item.vessel + ' / ' + item.voyage;
                            return item
                        });
                    }
                }).finally(() => {
                this.dataLoading = false;
            })
            bus.$emit("getVGMCount");
        },

        edit(item) { //编辑舱单
            let id = nanoid(5);
            let path = {
                title: item ? "编辑VGM" : '新建VGM',
                id: id,
                data: item,
                pathName: 'CreateVGM'
            }
            this.$router.push({name: path.pathName, params: path.data})
        },
        getSelection(e) { //获取table组件传来的多选信息
            this.multipleSelection = e
        },


    },
}
