<template>
  <div>
    <AllVGMSearchBar id="VGMSearchBar" @edit="edit" @search="searchList" :multiple-selection="multipleSelection"
                     :type="search_completionstatus"></AllVGMSearchBar>
    <VGMTable
        :type="search_completionstatus"
        :curr-page="page"
        :page-size="pageSize"
        :total-page="totalPage"
        :total-count="totalCount"
        :table-header="tableHeaders"
        :table-data="tableData"
        @fromChild="getSelection"
        @refresh="getList"
        @edit="edit"
        @copy="edit"
        @nextPage="nextPage"
        @pageSizeChange="pageSizeChange"
        ref="ShManifestTable">
    </VGMTable>
  </div>
</template>

<script>
import mixin from '@/mixin/vgm/vgmTable'
import AllVGMSearchBar from "@/views/VGM/AllVGM/AllVGMSearchBar.vue";
import {getAllUserManifestList} from "@/api/shanghaiManifest";
import {getAllUserVGMList} from "@/api/vgm";

export default {
  mixins: [mixin],
  name: "AllVGMTable",
  components: {
    AllVGMSearchBar,
  },
  data() {
    return {
      search_completionstatus: 3, //搜索的舱单状态
    }
  },
  methods:{
    getList() {
      let data = {
        ...this.search,
        id: this.$store.state.user.userId,
        page: this.page,
        limit: this.pageSize,
      }
      // this.$nextTick(() => {
      //   this.$refs.ShManifestTable.init(this.tableData, this.tableHeaders, 0)
      // })
      this.dataLoading = true;
      getAllUserVGMList(data)
          .then((response) => {
            if (response.data.page) {
              this.totalPage = response.data.page.totalPage;
              this.totalCount = response.data.page.totalCount;
              this.currPage = response.data.page.currPage;
              this.pageSize = response.data.page.pageSize;
              this.tableData = response.data.page.list.map((item) => {
                item.vesselvoyage = item.vessel + ' / ' + item.voyage;
                return item
              });
            }
          }).finally(() => {
        this.dataLoading = false;
      })

      // bus.$emit("getCountNum");
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
