<template>
    <div>
      <el-dialog title="VGM详情" :visible.sync="centerDialogVisible" width="70%" center>
        <div class="the_content">
          <BaseFormModule :item="baseForm" ref="baseForm" :is-look="true"></BaseFormModule>
          <div class="the_title">集装箱信息</div>
          <VGMContainerTable ref="ManifestGoodsTable" :item="goodsList" :is-look="true"></VGMContainerTable>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  
  import BaseFormModule from "@/views/VGM/components/baseInfo/BaseFormModule.vue";
  import VGMContainerTable from "@/views/VGM/components/container/VGMContainerTable.vue";

  import {
    getDetailById,
  } from "@/api/vgm";
  
  export default {
    components: { BaseFormModule, VGMContainerTable},
    data() {
      return {
        centerDialogVisible: false,
        detail: {
          head: {},
          contacts: [],
          goods: []
        },
        baseForm: {},
        //货物明细
        goodsList: [],
      }
    },
    methods: {
      init(item) {
        this.centerDialogVisible = true;
        console.log('item detail', item);
        this.getDetail(item.id)
      },
      getDetail(id) {
        getDetailById(id)
            .then((response) => {
              this.detail = response.data.data;
              this.baseForm = this.detail.bizVgmEntity;
              this.goodsList = this.detail.bizVgmContainerEntityList
              console.log(response);
            })
      },
    }
  }
  </script>
  
  <style lang="stylus" scoped>
  .the_content {
    height: 60vh;
    clear: both;
    overflow-y: scroll;
  
    .the_title {
      user-select: none;
      cursor: pointer;
      padding: 0.6rem 0;
      font-size: 16px;
      text-align: center;
      background: var(--GRAY-dcdcdc);
      margin-bottom: 1rem;
    }
  }
  </style>
  